(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-selectors/assets/javascripts/wallet-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-selectors/assets/javascripts/wallet-selectors.js');
"use strict";


const {
  createSelector
} = RTK;
const {
  WALLET_MARKETPLACE_RETAILER
} = svs.components.tipsen.wallet.walletType;
const {
  recountRetailBalance
} = svs.components.marketplace.retailCommissionCounter;
const {
  WALLET_ID_PLAYER
} = svs.components.tipsen.wallet.walletId;
const selectWallets = state => state.Wallet.wallets;
const selectActiveWallet = state => state.Wallet.wallets[state.Wallet.activeWallet];
const selectPlayerWalletBalance = state => {
  var _state$Wallet$wallets;
  return (_state$Wallet$wallets = state.Wallet.wallets[WALLET_ID_PLAYER]) === null || _state$Wallet$wallets === void 0 || (_state$Wallet$wallets = _state$Wallet$wallets.meta) === null || _state$Wallet$wallets === void 0 ? void 0 : _state$Wallet$wallets.Balance;
};
const selectWalletBalance = createSelector(selectActiveWallet, activeWallet => {
  if (!activeWallet) {
    return null;
  }
  if (activeWallet.type === WALLET_MARKETPLACE_RETAILER) {
    const {
      soldShares,
      sharePrice,
      commissionPercent
    } = activeWallet.meta;
    return recountRetailBalance(soldShares, sharePrice, commissionPercent);
  }
  return activeWallet.meta.balance || activeWallet.meta.Balance;
});
const selectCommissionOptions = createSelector(selectActiveWallet, activeWallet => {
  if (!activeWallet || activeWallet.type !== WALLET_MARKETPLACE_RETAILER) {
    return null;
  }
  const {
    soldShares,
    sharePrice
  } = activeWallet.meta;
  const items = [];
  for (let commissionPercent = 0; commissionPercent <= 10; commissionPercent++) {
    items.push({
      value: commissionPercent,
      amount: recountRetailBalance(soldShares, sharePrice, commissionPercent)
    });
  }
  return items;
});
setGlobal('svs.components.tipsen.walletSelectors', {
  selectPlayerWalletBalance,
  selectWallets,
  selectActiveWallet,
  selectWalletBalance,
  selectCommissionOptions
});

 })(window);