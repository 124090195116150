(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-selectors/assets/javascripts/use-wallets.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-selectors/assets/javascripts/use-wallets.js');
"use strict";

const _excluded = ["WALLET_ID_PLAYER"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  selectWallets
} = svs.components.tipsen.walletSelectors;
const useWallets = () => {
  const _useSelector = useSelector(selectWallets),
    {
      WALLET_ID_PLAYER: playerWallet
    } = _useSelector,
    objWallets = _objectWithoutProperties(_useSelector, _excluded);
  return useMemo(() => {
    const sortedWallets = Object.values(objWallets).sort((w1, w2) => {
      if (w1.label < w2.label) {
        return -1;
      }
      if (w1.label > w2.label) {
        return 1;
      }
      return 0;
    });
    return [playerWallet, ...sortedWallets].filter(Boolean);
  }, [playerWallet, objWallets]);
};
setGlobal('svs.components.tipsen.walletSelectors.useWallets', useWallets);

 })(window);